
angular.module('app')
    .controller('inmueblesController', ['$scope', inmueblesController])
    ;

function inmueblesController($scope) {

    $scope.changeWithFiltersPage = function(e, page) {
        e.preventDefault();
        var urlParams = window.location.search.replace("?", "").split("&");        
        
        var newUrlParams = "";
        
        for(var index = 0; index < urlParams.length; index++) {
            var element = urlParams[index];
            var split = element.split("=");
            if(split[0] != "page") {
                if(newUrlParams == "") {
                    newUrlParams = newUrlParams + split[0] + "=" + split[1];
                } else {
                    newUrlParams = newUrlParams + "&" + split[0] + "=" + split[1];
                }
            }
        }

        if(newUrlParams == "") {
            newUrlParams = "?" + newUrlParams + "page=" + page;
        } else {
            newUrlParams = "?" + newUrlParams + "&" + "page=" + page;
        }

        window.location = newUrlParams;

    }

}
