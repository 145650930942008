
angular.module('app')
    .factory('ContactoService', ['$http', ContactoService])
    ;

function ContactoService($http) {
    return {
        registro_contacto: function(params) {
            return $http({
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                url: '/inmuebles/registro_contacto',
                data: params
            });
        }
    }
}
