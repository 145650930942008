
angular.module('app')
    .controller('contactoController', ['$scope', 'ContactoService', '$timeout', contactoController])
    ;

function contactoController($scope, ContactoService, $timeout) {

    $scope.succes_message = false;
    $scope.formData = {
        "nombre" : "",
        "correo" : "",
        "telefono" : "",
        "mensaje" : ""
    };

    $scope.sendForm = function(form, contact_info) {
        console.log(form, contact_info);
        $scope.succes_message = false;
        if(form.$valid) {
            ContactoService.registro_contacto(contact_info)
                .then(function() {
                    clear_form();
                    formReset(form);
                    $scope.succes_message = true;
                    $timeout(function() {
                        $scope.succes_message = false;
                    }, 3000);
                }, function(response) {
                    console.log(response);
                });
        } else {
            if(angular.isDefined(form.$error.required)) {
                for (var index = 0; index < form.$error.required.length; index++) {
                    var element = form.$error.required[index];
                    console.log(element);
                    element.$touched = true;
                    element.$valid = false;
                }
            }
        }
    }

    function clear_form() {
        $scope.formData.nombre      = "";
        $scope.formData.correo      = "";
        $scope.formData.telefono    = "";
        $scope.formData.mensaje     = "";
    }

    function formReset(form) {
        if (form) {
          form.$setPristine();
          form.$setUntouched();
        }
    };

}